import React, { useEffect, useState } from "react";
import {
  Bars3Icon,
  PencilSquareIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";
import { useForm, FormProvider } from "react-hook-form";
import TestSidebar from "./components/TestSidebar";
import SelectTestimonials from "./components/SelectTestimonials";
import DesignForm from "./components/DesignForm";
import Button from "components/Button";
import IconButton from "components/IconButton";
import {
  ChatBubbleLeftRightIcon,
  PaintBrushIcon,
  PencilIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import API from "API";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NavLink, redirect } from "react-router-dom";

export default function NewPage() {
  const [widgetName, setWidgetName] = useState("Untitled Widget");
  const [editable, setEditable] = useState(false);
  const [selectedTestimonials, setSelectedTestimonials] = useState([]);


  const [newWidget, setNewWidget] = useState();
  const [bgColor, setBgColor] = useState("#7F56D9");
  const [title, setTitle] = useState("Wall of Love");
  const [description, setDescription] = useState("Here's what my clients say about me");

  const [sidebarItems, setSidebarItems] = useState([
    {
      name: "Select testimonials",
      current: true,
      icon: ChatBubbleLeftRightIcon,
    },
    { name: "Design", current: false, icon: PaintBrushIcon },
    { name: "Share", current: false, icon: ShareIcon },
  ]);

  const selectedTestimonialsData = useSelector(
    (state) => state.testimonials.selectedTestimonials
  );
  const testimonialsIds = selectedTestimonialsData.map(
    (testimonial) => testimonial._id
  );

  const createWidget = async () => {
    const payload = {
      name: widgetName,
      type: "wall_of_love",
      title: title,
      description: description,
      background: bgColor,
      testimonials: testimonialsIds,
    };
    try {
      const res = await API.createWidget(payload);
      if (res && res.status === 201) {
        toast.success((res.data.message || "Widget created."));
        setNewWidget(() => res.data.widget)
        setSidebarItems(sidebarItems.map((item, index) => {
          if (index === sidebarItems.length - 1) {
            return { ...item, current: true };
          }
          else {
            return { ...item, current: false };
          }

        }))
      } else {
        toast.error(res.message || "Widget Not Created");
      }
    } catch (error) {

    }
  };
  const formMethods = useForm();

  const handleSidebarClick = (index) => {

    const updatedItems = sidebarItems.map((item, idx) => ({
      ...item,
      current: idx === index,
    }));
    setSidebarItems(updatedItems);
  };

  const saveForm = (data) => {

    createWidget();


  };

  const handleChange = (event) => {
    setWidgetName(event.target.value);
  };
  return (
    <FormProvider {...formMethods}>
      <div className="flex flex-col h-screen">
        {/* Header */}
        <header className="px-8 pt-6 pb-4 border-b border-gray-200 bg-white flex justify-between">
          <div className="flex items-center gap-2">
            <NavLink to={'/widgets'}>
              <ArrowLeftIcon

                className="w-5 h-5 cursor-pointer stroke-2"
              />

            </NavLink>
            {editable ? (
              <input
                type="text"
                value={widgetName}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            ) : (
              <h1 className="text-xl font-semibold">{widgetName}</h1>
            )}
            <IconButton
              icon={PencilIcon}
              onClick={() => setEditable((prev) => !prev)}
            />
          </div>
          <div className="flex gap-2">
            <Button size="lg" variant="secondary-gray" onClick={``}>
              Discard
            </Button>
            <Button size="lg" onClick={formMethods.handleSubmit(saveForm)}>
              Save
            </Button>
            <Button size="lg" onClick={``}>
              Share
            </Button>
          </div>
        </header>

        {/* Sidebar and Main Content */}
        <div className="flex flex-1">
          <aside className="hidden lg:block lg:w-40 lg:h-full bg-white border-r border-gray-200">
            <TestSidebar
              items={sidebarItems}
              setItems={setSidebarItems}
              onSidebarItemClick={handleSidebarClick}
            />
          </aside>

          <main className="flex-1 h-full bg-purple-50 overflow-y-auto">
            {sidebarItems[0].current && (
              <SelectTestimonials
                editable={editable}
                setSelectedTestimonials={setSelectedTestimonials}
              />
            )}
            {sidebarItems[1].current && (
              <DesignForm
                selectedTestimonials={selectedTestimonials}
                onShareClick={() => handleSidebarClick(2)}
                isShareActive={sidebarItems[2].current}
                bgColor={bgColor}
                setBgColor={setBgColor}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
              />
            )}
            {sidebarItems[2].current && (
              <DesignForm
                selectedTestimonials={selectedTestimonials}
                onShareClick={() => handleSidebarClick(1)}
                isShareActive={true}
                NewWidget={newWidget}
                bgColor={bgColor}
                setBgColor={setBgColor}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}

              />
            )}
            {!sidebarItems[0].current &&
              !sidebarItems[1].current &&
              !sidebarItems[2].current && (
                <div>
                  <h2 className="text-lg">Main Content Area</h2>
                </div>
              )}
          </main>
        </div>
      </div>
    </FormProvider>
  );
}
