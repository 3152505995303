import { FormProvider } from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import React from "react";
import { useForm } from "react-hook-form";
import { loginUser } from "../../redux/authService";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_SOURCE } from "../../utils/constant";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";

export default function LoginForm() {
    const dispatch = useDispatch();
    const methods = useForm();
    const { register, handleSubmit } = methods;
    const { status } = useSelector((state) => state.auth);
    console.log(useSelector((state) => state.auth), 'Hassan')
    const submitForm = async (formData) => {
        try {
            const result = await dispatch(
                loginUser({
                    username: formData.email,
                    email: formData.email,
                    password: formData.password,
                    rememberMe: formData.rememberMe,
                    authSource: AUTH_SOURCE.SELF
                })
            )
            if (result?.error) {
                toast.error(result.error.message)
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    const windowWith = window.innerWidth
    const responseMessage = (response) => {
        try {
            dispatch(
                loginUser({
                    clientId: response.clientId,
                    googleCredential: response.credential,
                    authSource: AUTH_SOURCE.GOOGLE
                })
            );
        } catch (ex) {
            console.log(ex);
        }
    };
    const errorMessage = (error) => {
        console.log(error, "e");
    };
    // const googleLoginSubmit = useGoogleLogin({
    //     flow:"implicit",
    //     onSuccess: tokenResponse => {
    //         console.log(tokenResponse,"tokenResponse")
    //     },
    //     onError: error => {
    //         console.log(error,"error")
    //     }
    // });
    return (
        <>
            <FormProvider methods={methods} className="space-y-6" onSubmit={handleSubmit(submitForm)}>
                <RHFInputField
                    name="email"
                    label="Email address"
                    type="email"
                    placeholder="Enter your email address"
                    autoComplete="email"
                    required
                />
                <RHFInputField
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    autoComplete="current-password"
                    required
                />

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600"
                            {...register("rememberMe")}
                        />
                        <label
                            htmlFor="remember-me"
                            className="ml-3 block text-sm leading-6 text-gray-900"
                        >
                            Remember me
                        </label>
                    </div>

                    <div className="text-sm leading-6">
                        <NavLink
                            to="/forgot-password"
                            className="font-semibold text-brand-700 text-sm transition duration-500 hover:opacity-90"
                        >
                            Forgot password?
                        </NavLink>
                    </div>
                </div>

                <div>
                    <Button variant="primary" width={"full"} size="lg" type="submit" isLoading={status === "loading"}>
                        Sign in
                    </Button>
                </div>
            </FormProvider>
            <div className="mt-4">
                {/*<GoogleSignInButton*/}
                {/*    disabled={status === "loading"}*/}
                {/*    onClick={googleLoginSubmit}*/}
                {/*/>*/}
                <div className="w-full flex items-center justify-center">
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} size="large" shape="square" width={windowWith < 420 ? "250" : "360"} logo_alignment="center" auto_select={false} />
                </div>
            </div>
        </>
    )
}