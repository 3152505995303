import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import RHFInputField from "components/common/RHF/RHFTextField";
import UserTestimonial from "components/UserTestimonial";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import API from "API";
import { useDispatch, useSelector } from "react-redux";
import { removeTestimonial, toggleTestimonial } from "../../redux/testimonialSlice";


const TickMark = () => (
    <div className="absolute top-4 right-3 bg-green-200 rounded-full p-1 flex items-center justify-center">
        <svg
            className="w-4 h-4 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 12l5 5L20 7"
            />
        </svg>
    </div>
);

const CrossMark = ({ onClick }) => (
    <div
        onClick={onClick}
        className="absolute top-4 right-3 bg-red-200 rounded-full p-1 flex items-center justify-center cursor-pointer"
    >
        <svg
            className="w-4 h-4 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    </div>
);

export default function SelectTestimonials({ editable = true }) {
    const methods = useForm({
        defaultValues: {
            title: "Select Testimonials",
            search: "",
        },
    });

    const [manualSelection, setManualSelection] = useState(false);
    const [videoTestimonials, setVideoTestimonials] = useState(true);
    const [customerPhotos, setCustomerPhotos] = useState(true);
    // const [selectedTestimonials, setSelectedTestimonials] = useState([]);
    const [testimonialData, setTestimonialData] = useState([])
    const testimonials = [
        {
            avatar: "https://placehold.co/40x40",
            name: "Muhammad Anas",
            position: "Senior UX Designer at Bussfly | Ex-Sony",
            date: "Oct 6, 2023",
            testimonial: "Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.",
            video: null,
            ratingName: "userRating1",
        },
        {
            avatar: "https://placehold.co/40x40",
            name: "Sarah Khan",
            position: "Product Manager at TechCorp",
            date: "Oct 5, 2023",
            testimonial: "The experience was fantastic! I learned a lot from the sessions. The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.",
            video: null,
            ratingName: "userRating2",
        },
        {
            avatar: "https://placehold.co/40x40",
            name: "Ali Raza",
            position: "Software Engineer at WebSolutions",
            date: "Sep 30, 2023",
            testimonial: "Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.",
            video: null,
            ratingName: "userRating3",
        },
        {
            avatar: "https://placehold.co/40x40",
            name: "Fatima Zafar",
            position: "UX Researcher at DesignCo",
            date: "Sep 28, 2023",
            testimonial: "I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.",
            video: null,
            ratingName: "userRating4",
        },
        {
            avatar: "https://placehold.co/40x40",
            name: "Omer Ahmed",
            position: "Data Analyst at DataFirm",
            date: "Sep 25, 2023",
            testimonial: "This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.",
            video: null,
            ratingName: "userRating5",
        },
        {
            avatar: "https://placehold.co/40x40",
            name: "Hina Bashir",
            position: "Marketing Specialist at MarketPro",
            date: "Sep 22, 2023",
            testimonial: "I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.",
            video: null,
            ratingName: "userRating6",
        },
    ];
    const dispatch = useDispatch()

    const selectedTestimonials = useSelector((state) => state.testimonials.selectedTestimonials)
    // const toggleTestimonial = (testimonial) => {
    //     if (selectedTestimonials.some((t) => t._id === testimonial._id)) {
    //         setSelectedTestimonials(selectedTestimonials.filter((t) => t._id !== testimonial._id));
    //     } else {
    //         setSelectedTestimonials([...selectedTestimonials, testimonial]);
    //     }
    // };

    // const removeTestimonial = (testimonial) => {
    //     setSelectedTestimonials(selectedTestimonials.filter((t) => t._id !== testimonial._id));
    // };
    const fetchAllTestimonial = async (type) => {

        try {

            const res = await API.getAllTestimonial(type);
            // if (videoTestimonials === false) {
            //     setTestimonialData(testimonialData.filter((e) => e.type === 'text'))
            // }
            // else {

            // }
            setTestimonialData(res.data)
            // setTestimonialForms(res.result);
            // setFilterItemList(prev => [{
            //   id: "all",
            //   content:<p>All</p>,
            // }, ...res.result.map((item) => ({
            //   id: item._id,
            //   content: <p>{item.title}</p>
            // }))]);
        } catch (error) {
            // setStatus(error.message);
        } finally {
            // setStatus("loaded");
        }
    }
    useEffect(() => {
        if (videoTestimonials) {
            fetchAllTestimonial()
        }

        else {
            fetchAllTestimonial('text')
        }
    }, [videoTestimonials]);



    return (
        <FormProvider {...methods}>
            <div className="flex flex-row flex-1 overflow-hidden h-full bg-purple-50">
                <div className="w-80 p-4 flex flex-col h-full bg-purple-50">
                    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                        <span
                            className={`${!manualSelection ? "text-purple-600 bg-purple-50" : "hover:bg-purple-50 hover:text-purple-600"
                                } cursor-pointer mb-1 rounded p-2 transition-colors duration-200 font-bold block`}
                            onClick={() => {
                                setManualSelection(false);
                                methods.setValue("search", "");
                                // setSelectedTestimonials([]);
                            }}
                        >
                            All approved testimonials
                        </span>
                        <span
                            className={`${manualSelection ? "text-purple-600 bg-purple-50" : "hover:bg-purple-50 hover:text-purple-600"
                                } cursor-pointer mb-1 rounded p-2 transition-colors duration-200 font-bold block`}
                            onClick={() => {
                                setManualSelection(true);
                                methods.setValue("search", "");
                            }}
                        >
                            Manual selection
                        </span>
                    </div>

                    {/* Filters Container */}
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <h2 className="text-lg font-bold">Filters</h2>
                        <hr className="mb-4 mt-2" />
                        {manualSelection && (
                            <RHFInputField
                                name="search"
                                label=""
                                icon={MagnifyingGlassIcon}
                                placeholder="Search testimonials..."
                                inputClassName="border p-2 w-full rounded mb-4"
                                required={false}
                            />
                        )}
                        <hr className="mb-4 mt-2" />
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2">
                                <span>Video testimonials</span>
                                <label className="relative inline-flex items-center cursor-pointer ml-2">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={videoTestimonials}
                                        onChange={() => setVideoTestimonials(!videoTestimonials)}
                                    />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-purple-600"></div>
                                    <span className="absolute left-1 top-0.5 w-4 h-4 bg-white rounded-full peer-checked:translate-x-full transition-all"></span>
                                </label>
                            </div>
                            <div className="flex items-center mb-2">
                                <span>Customer photos</span>
                                <label className="relative inline-flex items-center cursor-pointer ml-2">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={customerPhotos}
                                        onChange={() => setCustomerPhotos(!customerPhotos)}
                                    />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-purple-600 ml-2"></div>
                                    <span className="absolute left-1 top-0.5 w-4 h-4 bg-white rounded-full peer-checked:translate-x-full transition-all ml-2"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* TestimonialsArea */}
                <div className="flex-grow p-8 bg-purple-50 flex items-start justify-center pt-16 overflow-auto">
                    <div className="flex-grow flex flex-col gap-4 ">
                        <div className={`grid ${manualSelection ? "grid-cols-2" : "grid-cols-3"} gap-4`}>
                            {testimonialData.map((testimonial) => (
                                <div
                                    key={testimonial._id}
                                    onClick={() => dispatch(toggleTestimonial(testimonial))}
                                    className={`relative cursor-pointer p-4 rounded-lg bg-white shadow-md shadow-gray-200 ${selectedTestimonials.some((t) => t._id === testimonial._id)
                                        ? "border-purple-600 bg-purple-50"
                                        : "border-gray-200"
                                        }`}
                                >
                                    <UserTestimonial
                                        avatar={testimonial.image}
                                        name={testimonial.name}
                                        position={testimonial.position}
                                        date={testimonial.date}
                                        testimonial={testimonial.testimonial}
                                        video={testimonial.type === 'video' && testimonial.video}
                                        ratingName={testimonial._id}
                                        rating={testimonial.stars}
                                    />


                                    {selectedTestimonials.some((t) => t._id === testimonial._id) && <TickMark />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Sidebar */}
                {manualSelection && (
                    <div className="w-80 h-full border-l border-gray-200 bg-white px-4 py-8">
                        <h2 className="text-lg font-bold">Selected Testimonials</h2>
                        <div className="mt-2 shadow-md shadow-gray-200">
                            {selectedTestimonials.length > 0 ? (
                                selectedTestimonials.map((testimonial) => (
                                    <div className="relative" key={testimonial.ratingName}>
                                        <UserTestimonial
                                            avatar={testimonial.avatar}
                                            name={testimonial.name}
                                            position={testimonial.position}
                                            date={testimonial.date}
                                            testimonial={testimonial.testimonial}
                                            video={testimonial.video}
                                            ratingName={testimonial.ratingName}
                                        />
                                        <CrossMark onClick={() => dispatch(removeTestimonial(testimonial))} />
                                    </div>
                                ))
                            ) : (
                                <div className="border border-light-gray bg-white bg-opacity-50 rounded-lg flex items-center justify-center mt-2" style={{ height: "250px", width: "250px" }}>
                                    <span>No testimonials selected</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </FormProvider>
    );
}
