import API from 'API';
import UserTestimonial from 'components/UserTestimonial'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const WallOfLoveDesign = () => {
    const formMethods = useForm();
    const params = useParams()
    const [widgetData, setwidgetData] = useState(null)
    const getWidgetDataById = async () => {

        try {
            const res = await API.getWidgetDataById(params.id);

            if (res && res.status === 200) {
                setwidgetData(() => res.data)
                console.log(res)
                toast.success((res.data.message));

            } else {
                toast.error(res.message || "Fetch Widget Not Success");
            }
        } catch (error) {

        }
    };
    useEffect(() => {
        getWidgetDataById()


    }, [])

    return (
        <FormProvider {...formMethods}>
            <div className="flex flex-row flex-1 overflow-hidden h-full">
                <div className="flex-grow p-0 m-0 overflow-hidden" >
                    <div
                        className="text-white py-16"
                        style={{
                            background: `linear-gradient(to bottom, ${widgetData?.background} 30%, #EAE8F5 30%)`,
                            height: '100%',
                        }}
                    >
                        <div className="container mx-auto px-4">
                            <h1 className="text-4xl font-bold text-center mb-2">{widgetData?.title}</h1>
                            <p className="text-center">{widgetData?.description}</p>
                        </div>
                        <div className="flex-grow bg-transparent">
                            <div className="grid grid-cols-3 gap-2 mt-4 justify-items-center">
                                {widgetData?.testimonials.map((testimonial, index) => (
                                    <div key={index} className="border-b border-gray-200 p-2 bg-transparent">
                                        <UserTestimonial
                                            avatar={testimonial.image}
                                            name={testimonial.name}
                                            position={testimonial.position}
                                            date={testimonial.date}
                                            testimonial={testimonial.testimonial}
                                            video={testimonial.type === 'video' && testimonial.video}
                                            ratingName={testimonial._id}
                                            rating={testimonial.stars}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </FormProvider>

    )
}

export default WallOfLoveDesign