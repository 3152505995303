import { Fragment } from 'react';
import { PencilSquareIcon, Bars3Icon } from "@heroicons/react/24/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function TestSidebar({ items, setItems, setRightSidebarOpen }) {
    const handleItemClick = (itemName) => {
        const updatedItems = items.map((item) => ({
            ...item,
            current: item.name === itemName,
        }));

        setItems(updatedItems);
    };

    return (
        <>
            <div className="w-40 bg-white border-r border-gray-200 h-full flex-none">
                <div className="flex flex-col gap-y-5 overflow-y-auto px-4 py-6">
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-4">
                            <li>
                                <h6 className="text-xs font-semibold leading-6 text-gray-400 mb-2">
                                    PAGES
                                </h6>
                                <div className="flex flex-col gap-2">
                                    {items.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={() => handleItemClick(item.name)}
                                            className={classNames(
                                                item.current
                                                    ? "bg-purple-100 text-purple-800" 
                                                    : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                                                "group flex flex-col items-center rounded-md py-2 px-3 text-base leading-6 font-semibold"
                                            )}
                                        >
                                            <item.icon
                                                className={"text-gray-500 h-6 w-6 shrink-0 stroke-2"}
                                                aria-hidden="true"
                                            />
                                            <span className="mt-1">{item.name}</span> 
                                        </button>
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}
