import { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  LifebuoyIcon,
  ArrowUpTrayIcon,
  Bars3Icon,
  ChatBubbleLeftRightIcon,
  InboxIcon,
  PuzzlePieceIcon,
  XMarkIcon,
  CogIcon
} from "@heroicons/react/24/outline";
import Logo from "../components/Logo";
import API from "../API";
import { useSelector } from "react-redux";

const GatherSection = [
  {
    name: "Testimonials",
    href: "/dashboard",
    icon: ChatBubbleLeftRightIcon,
    current: false,
  },
  { name: "Forms", href: "/forms", icon: InboxIcon, current: false },
  { name: "Import", href: "/import-testimonials", icon: ArrowUpTrayIcon, current: false },
];
const ShareSection = [
  {
    name: "Widgets",
    href: "/widgets",
    icon: PuzzlePieceIcon,
    current: false,
  },
  // {
  //   name: "Share",
  //   href: "/share",
  //   icon: ArrowTopRightOnSquareIcon,
  //   current: false,
  // },
];
const FooterSection = [
  // {
  //   name: "Support",
  //   href: "/widgets",
  //   icon: LifebuoyIcon,
  //   current: false,
  // },
  {
    name: "Settings",
    href: "/profile",
    icon: CogIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const checkIfCurrent = (path) => {
    return location.pathname === path;
  };
  const [userData, setUserData] = useState(null)
  const user = useSelector((state) => state.user)
  useEffect(() => {
    (async () => {
      // const res = await API.ensureLogin();

      // setUserData(res.user);


      setUserData(user);
    })()
  }, [user])
  useEffect(() => {
    (async () => {
      const res = await API.ensureLogin();
      setUserData(res.user);
    })()
  }, [])

  return (
    <>
      <>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <Logo />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {GatherSection.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  className={classNames(
                                    checkIfCurrent(item.href)
                                      ? "bg-indigo-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      checkIfCurrent(item.href)
                                        ? "bg-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-5 w-5 shrink-0 stroke-2"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            SHARE
                          </div>
                          <ul className="-mx-2 mt-2 space-y-1">
                            {ShareSection.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  className={classNames(
                                    checkIfCurrent(item.href)
                                      ? "bg-indigo-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      checkIfCurrent(item.href)
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-5 w-5 shrink-0 stroke-2"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:inset-y-0 lg:z-50 lg:flex overflow-y-auto lg:flex-col h-full flex-none">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="w-[281px] py-8 flex grow flex-col gap-y-5 border-r border-gray-200 bg-white px-4">
            <Logo className="" />
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-6">
                <li>
                  <h5 className="pb-3 text-xs font-semibold leading-6 text-gray-500">
                    GATHER
                  </h5>
                  <ul className="flex flex-col gap-2">
                    {GatherSection.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            checkIfCurrent(item.href)
                              ? "bg-gray-100 text-gray-800"
                              : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                            "group flex items-center gap-x-3 rounded-md py-2 px-3 text-base leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              "text-gray-500 h-6 w-6 shrink-0 stroke-2"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <h5 className="pb-3 text-xs font-semibold leading-6 text-gray-500">
                    SHARE
                  </h5>
                  <ul className="flex flex-col gap-2">
                    {ShareSection.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            checkIfCurrent(item.href)
                              ? "bg-gray-100 text-gray-800"
                              : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                            "group flex items-center gap-x-3 rounded-md py-2 px-3 text-base leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              "text-gray-500 h-6 w-6 shrink-0 stroke-2"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <ul className="mb-6 flex flex-col gap-2">
                    {FooterSection.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={`${item.href}`}
                          className={classNames(
                            checkIfCurrent(item.href)
                              ? "bg-gray-100 text-gray-800"
                              : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                            "group flex items-center gap-x-3 rounded-md py-2 px-3 text-base leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              "text-gray-500 h-6 w-6 shrink-0 stroke-2"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  {/*<div className="bg-gray-50 px-4 py-5 rounded-lg ">*/}
                  {/*  <h5 className="pb-1 text-gray-900 font-semibold text-sm">45/50 Testimonials</h5>*/}
                  {/*  <p className="text-gray-600 text-sm ">You have used 80% of your testimonials space. Need more?</p>*/}
                  {/*  <progress value="80" max="100" className="my-4 h-2 bg-brand-600 rounded [&::-webkit-progress-bar]:rounded*/}
                  {/*   [&::-webkit-progress-value]:bg-brand-600 [&::-webkit-progress-value]:rounded [&::-webkit-progress-bar]:bg-gray-200 [&::-moz-progress-bar]:rounded"/>*/}
                  {/*  <div className="flex items-center gap-3">*/}
                  {/*    <button type="button" className="text-gray-600 text-sm font-semibold border-0 bg-transparent">Dismiss</button>*/}
                  {/*    <button type="button" className="text-brand-700 text-sm font-semibold border-0 bg-transparent">Upgrade plan</button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="flex items-center justify-between px-2 pt-6 mt-6 border-t border-t-gray-200">
                    <div className="flex items-center gap-3 ">
                      <img
                        className="object-contain h-10 w-10 rounded-full bg-gray-50"
                        src={`${process.env.REACT_APP_PROXY_URL}/${userData?.profile}` || "/img/imagePlaceholder.svg"}
                        referrerPolicy="no-referrer"
                        alt=""
                      />


                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true" className="text-gray-700 text-sm font-semibold">{userData?.fullName}</span>
                    </div>
                    <button type="button" onClick={() => navigate("/logout")} className="h-9 w-9 flex items-center justify-center rounded-lg hover:bg-gray-50">
                      <img
                        // className="h-5 w-5"
                        src="/img/icons/log-out.svg"
                        alt=""
                      />


                    </button>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <a href="/profile">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </a>
        </div>
      </>
    </>
  );
}
