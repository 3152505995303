import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import { ArrowUpTrayIcon, InboxIcon, TrashIcon, MagnifyingGlassIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import CardButton from "../components/CardButton";
import TestimonialRow from "../components/TestimonialRow";
import { useSelector } from "react-redux";
import API from "API";
import { toast } from "react-toastify";
import TestimonialSidePopover from "components/TestimonialSidePopover";
import Button from "components/Button";
import ButtonGroup from "components/ButtonGroup";
import InviteModal from "components/Testimonial/InviteModal";
import PageLoader from "../components/common/loaders/page-loader";
import { useForm } from "react-hook-form";
import { FormProvider } from "../components/common/RHF";
import RHFCheckbox from "../components/common/RHF/RHFCheckbox";
import Swal from "sweetalert2";
import RHFInputField from "../components/common/RHF/RHFTextField";
import BaseDropdown from "../components/common/dropdown/base-dropdown";


export default function Dashboard() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [editPopover, setEditPopover] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [status, setStatus] = useState("loading");
  const [dataIndex, setDataIndex] = useState(0);
  const [testimonialList, setTestimonialList] = useState([]);

  const [searchData, setSearchData] = useState("");
  const [filterItemList, setFilterItemList] = useState([]);
  const [testimonialForms, setTestimonialForms] = useState({})
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isForm, setIsForm] = useState(false);
  const methods = useForm({
    defaultValues: {
      selectAllCheckbox: false
    }
  })
  const { reset, watch, setValue } = methods;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  useEffect(() => {
    if (!!searchData) {
      searchData.forEach((item) => {
        setValue(`checkbox.${item._id}`, watch("selectAllCheckbox"))
      })
    }
  }, [watch("selectAllCheckbox")]);
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setSelectedCheckboxes(watch("checkbox") ? Object.keys(watch("checkbox"))?.map(el => watch("checkbox")[el] ? el : undefined).filter(Boolean) : [])
    )
    return () => subscription.unsubscribe()
  }, [watch])
  React.useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [isAuthenticated, navigate]);

  const updateStatus = React.useCallback(async (testimonial, e) => {
    if (testimonial.status !== e) {
      await toast.promise(API.updateTestimonialStatus(testimonial), {
        pending: "Updating Testimonial Status...",
        success: "Success",
        error: {
          render({ data }) {
            return `${data.message}`;
          },
        },
      });
      setDataIndex((d) => d + 1);
    }
  }, []);

  const fetchData = async (category, formId) => {
    try {
      setStatus("loading");
      const res = await API.listTestimonials(category, formId);
      const formResult = await API.listForms();
      setIsForm(formResult.result.length > 0)
      setTestimonialList(res.result);
      setSearchData(res.result);
      setStatus("loaded");
    } catch (error) {
      setStatus(error.message);
    }
  };
  useEffect(() => {
    fetchData("", selectedFilter && selectedFilter.id !== "all" ? selectedFilter.id : "");
  }, [dataIndex, setTestimonialList, setStatus, selectedFilter]);

  const fetchAllForms = async () => {
    try {
      setStatus("loading");
      const res = await API.listForms();
      setTestimonialForms(res.result);
      setFilterItemList(prev => [{
        id: "all",
        content: <p>All</p>,
      }, ...res.result.map((item) => ({
        id: item._id,
        content: <p>{item.title}</p>
      }))]);
    } catch (error) {
      setStatus(error.message);
    } finally {
      setStatus("loaded");
    }
  }
  useEffect(() => {
    fetchAllForms()
  }, []);
  useEffect(() => {
    if (typeof watch("search") === "string") {
      handleSearch(watch("search"))
    }
  }, [watch("search")]);

  const handleSearch = (value) => {
    if (value === "") return setSearchData(testimonialList);
    const filteredData = testimonialList.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setSearchData(filteredData);
  };

  const handleDeleteTestimonials = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await toast.promise(API.deleteMultipleTestimonials(selectedCheckboxes), {
            pending: "Loading",
            success: {
              render({ data }) {
                fetchData("", selectedFilter && selectedFilter.id !== "all" ? selectedFilter.id : "")
                reset()
                setSelectedCheckboxes([])
                return data.message;
              },
            },
            error: {
              render({ data }) {
                return data.message;
              },
            },
          });
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="h-screen flex flex-col lg:flex-row">
      <Sidebar />
      <div className="flex-grow p-8 overflow-auto">
        <h1 className="text-gray-900 text-xl lg:text-3xl font-semibold">
          Testimonials{" "}
          {`(${testimonialList.length})`}
        </h1>
        {!isForm || testimonialList.length <= 0 && (
          <>
            <h2 className="text-base lg:text-lg font-semibold mt-8">
              Start gathering testimonials
            </h2>

            <div className="flex gap-4 max-w-4xl mt-6">
              {!isForm && <CardButton
                href="/forms"
                extraClass={"flex-1"}
                title={"Create your first form"}
                description={"Create a form to start collecting testimonials"}
                icon={InboxIcon}
              />}
              {testimonialList.length <= 0 && <CardButton
                href="/import-testimonials"
                extraClass={"flex-1"}
                title={"Import existing testimonials as new post"}
                description={
                  "Dive into the editor and start creating"
                }
                icon={ArrowUpTrayIcon}
              />}
            </div>
          </>
        )}
        <div className="flex items-center justify-between mt-7 mb-4">
          <h3 className="text-base lg:text-lg text-gray-900 font-semibold">Testimonials</h3>
          <Button onClick={() => setInviteModalOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.3261 10.5062C17.5296 10.3318 17.6313 10.2446 17.6686 10.1409C17.7013 10.0498 17.7013 9.9502 17.6686 9.85914C17.6313 9.75539 17.5296 9.6682 17.3261 9.49383L10.2672 3.44331C9.917 3.14315 9.74191 2.99306 9.59367 2.98939C9.46483 2.98619 9.34177 3.04279 9.26035 3.14269C9.16667 3.25764 9.16667 3.48825 9.16667 3.94948V7.52886C7.38777 7.84007 5.75966 8.74146 4.54976 10.0949C3.23069 11.5704 2.50103 13.48 2.5 15.4591V15.9691C3.37445 14.9157 4.46626 14.0638 5.70063 13.4716C6.78891 12.9495 7.96535 12.6403 9.16667 12.5588V16.0505C9.16667 16.5117 9.16667 16.7424 9.26035 16.8573C9.34177 16.9572 9.46483 17.0138 9.59367 17.0106C9.74191 17.0069 9.917 16.8569 10.2672 16.5567L17.3261 10.5062Z"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {" "}
            <span>Send an Invite</span>
          </Button>
        </div>
        <div className="flex items-center justify-between   my-3">
          <ButtonGroup
            btnData={[
              { text: "All", onClick: () => fetchData("", selectedFilter && selectedFilter.id !== "all" ? selectedFilter.id : "") },
              { text: "Collected", onClick: () => fetchData("collected", selectedFilter && selectedFilter.id !== "all" ? selectedFilter.id : "") },
              { text: "Invited", onClick: () => fetchData("invited", selectedFilter && selectedFilter.id !== "all" ? selectedFilter.id : "") },
            ]}
          />
          <div className="flex items-center justify-center gap-5 w-[320px]">
            <FormProvider methods={methods} className="max-w-md w-full">
              {/*<InputField*/}
              {/*    icon={MagnifyingGlassIcon}*/}
              {/*    size="lg"*/}
              {/*    id="search"*/}
              {/*    placeholder="Search"*/}
              {/*    type="search"*/}
              {/*    autoComplete="search"*/}
              {/*    onChange={handleSearch}*/}
              {/*/>*/}
              <RHFInputField
                name="search"
                icon={MagnifyingGlassIcon}
                type="text"
                placeholder="Search for a testimonial"
                autoComplete="search"
                size="lg"
                className="max-w-[320px] ml-auto"
              />
            </FormProvider>
            <div>
              <BaseDropdown clickHandler={setSelectedFilter} itemList={filterItemList} selectedMenu={selectedFilter?.id}>
                <div
                  className="w-11 h-11 border border-gray-300 bg-white hover:bg-gray-50 rounded-full flex items-center justify-center">
                  <AdjustmentsHorizontalIcon className="w-5 h-5 text-gray-700 hover:text-gray-800" />
                </div>
              </BaseDropdown>
            </div>
          </div>
        </div>

        {selectedCheckboxes.length > 0 && <Button variant="secondary-gray" disabled={selectedCheckboxes.length <= 0}
          onClick={handleDeleteTestimonials}>
          <TrashIcon className="w-4 h-4 text-red-700" />{" "}
          <span>Confirm Deletion ({selectedCheckboxes.length})</span>
        </Button>}
        <div className="mt-4">
          {status === "loading" ? (
            <PageLoader />
          ) : (
            <FormProvider methods={methods}
              className="overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-600 sm:pl-6"
                    >
                      <RHFCheckbox name="selectAllCheckbox" />
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-600 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-600"
                    >
                      Testimonial
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-600"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-600"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-semibold text-gray-600"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {status === "loaded" ? (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {searchData.map((item) => (
                      <TestimonialRow
                        item={item}
                        updateStatus={(e) => updateStatus(item, e)}
                        handleOpen={() => {
                          setSelectedTestimonial(item);
                          setEditPopover(true);
                        }}
                        formMethods={methods}
                      />
                    ))}
                  </tbody>
                ) : (
                  <div className="text-red-500">{status}</div>
                )}
              </table>
            </FormProvider>
          )}
        </div>
      </div>
      <TestimonialSidePopover
        title={"Edit Testimonial"}
        open={editPopover}
        setOpen={setEditPopover}
        testimonial={selectedTestimonial}
        updateList={fetchData}
      />
      {inviteModalOpen && testimonialForms && <InviteModal open={inviteModalOpen} testimonialForms={testimonialForms} setOpen={setInviteModalOpen} />}
    </div>
  );
}
