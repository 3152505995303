import React, { useState } from "react";
import Button from "components/Button";

export default function Password() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setLoading(true);

    if (
      !formData.currentPassword ||
      !formData.newPassword ||
      !formData.confirmPassword
    ) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setError("New password and confirm password do not match.");
      setLoading(false);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="ml-2 mr-2 mt-2 mb-2">
      <h2 className="text-lg font-semibold mb-1">Password</h2>
      <p className="text-gray-500 mb-1 text-sm">
        Please enter your current password to change your password.
      </p>
      <hr className="border-t border-gray-300 mb-6 mt-6" />

      {/* {error && <p className="text-red-500 mb-4">{error}</p>}
            {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>} */}

      {/* Current Password */}
      <div className="flex items-center mb-4">
        <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">
          Current Password
        </label>
        <input
          type="password"
          name="currentPassword"
          value={formData.currentPassword}
          onChange={handleInputChange}
          className="mt-1 block w-1/3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-purple-500 sm:text-sm ml-4"
          required
        />
      </div>
      <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

      {/* New Password */}
      <div className="mb-4 flex items-center">
        <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">New Password</label>
        <div className="w-2/3">
          <input
            type="password"
            id="new-password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            className="w-1/2 px-3 py-2 border border-gray-300 rounded-md ml-4  rounded-md shadow-sm focus:ring-indigo-500 focus:border-purple-500 sm:text-sm"
            required
          />
          <p className="text-gray-500 text-xs mt-1 ml-6">
            Your new password must be more than 8 characters.
          </p>
        </div>
      </div>

      <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

      {/* Confirm New Password */}
      <div className="flex items-center mb-4">
        <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">
          Confirm New Password
        </label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          className="mt-1 block w-1/3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-purple-500 sm:text-sm ml-4"
          required
        />
      </div>
      <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

      {/* Submit Buttons */}
      <div className="mt-6 flex justify-end gap-2">
        <Button size="lg" variant="secondary-gray" disabled={loading}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
        <Button size="lg" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  );
}
