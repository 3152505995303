import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../layouts/Sidebar';
import { toast } from 'react-toastify';
import {
    ClipboardIcon,
    DocumentTextIcon,
    InboxIcon,
    MagnifyingGlassIcon,
    PencilIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import Button from '../components/Button';
import { PlusIcon } from '@heroicons/react/20/solid';
import InputField from '../components/InputField';
import IconButton from '../components/IconButton';
import { copyToClipboard } from '../utils/utils';
import { useSelector } from 'react-redux';
import API from 'API';
import Spinner from 'components/Spinner';

export default function Widgets() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isAuthenticated) navigate('/login');
    }, [isAuthenticated, navigate]);

    return (
        <div className="h-screen flex flex-col lg:flex-row">
            <Sidebar />
            <div className="flex-grow p-8">
                <h1 class="text-3xl font-semibold flex items-baseline gap-2">
                    Widgets
                </h1>
                <h2 className="text-xl font-semibold mt-12">
                    Standalone Pages
                </h2>
                <div className="mt-4">
                    <a href="widgets/wall-of-love" className="widget-box">
                        <img src="../img/walloflove.png" alt="Wall of love" />
                    </a>
                </div>
                <h2 className="text-xl font-semibold mt-12">
                    Embeddable Widgets
                </h2>
                <div className="mt-4">
                    <a href="widgets/testimonial-list" className="widget-box">
                        <img
                            src="../img/embed-box.png"
                            alt="Embeddable testimonials"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}
