import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../redux/authService";
import AuthWrapper from "../../layouts/Auth/AuthWrapper";
import LoginForm from "../../components/Auth/LoginForm";

export default function Login({ logout }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, status } = useSelector((state) => state.auth);
    React.useEffect(() => {
        if (logout) {
            dispatch(logoutUser());
            navigate("/login");
        } else if (isAuthenticated) {
            navigate("/dashboard");
        }
    }, [isAuthenticated, navigate]);


    return (
        <AuthWrapper title="Welcome back" description="Welcome back! Please enter your details.">
            <>
                <LoginForm />
                <p className="mt-10 text-center text-sm text-gray-600">
                    {`Don’t have an account? `}
                    <NavLink
                        to="/register"
                        className="font-semibold text-brand-700 transition duration-500 hover:opacity-90"
                    >
                        Signup
                    </NavLink>
                </p>
            </>
        </AuthWrapper>
    );
}
