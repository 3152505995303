import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../layouts/Sidebar';
import { useSelector } from 'react-redux';
import PersonalInfo from 'components/Settings/personalinfo';
import Password from 'components/Settings/password';

export default function Profile() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Profile');

    React.useEffect(() => {
        if (!isAuthenticated) navigate('/login');
    }, [isAuthenticated, navigate]);

    const tabs = [
        { name: 'Profile', component: <PersonalInfo/>},
        { name: 'Password', component: <Password/> },
        { name: 'Team', component: <div>Team</div> },
        { name: 'Plan', component: <div>Plan</div> },
        { name: 'Billing', component: <div>Billing</div> },
        { name: 'Notifications', component: <div>Notifications</div> },
        { name: 'Integrations', component: <div>Integrations</div> },
    ];

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="h-screen flex flex-col lg:flex-row">
            <Sidebar />
            <div className="flex-grow p-8 overflow-auto">
                <h1 className="text-3xl font-semibold flex items-baseline gap-2">
                    Profile Settings
                </h1>
                <nav className="mt-8 mb-4 flex" aria-label="Tabs">
                    {tabs.map((tab, index) => (
                        <button
                            key={tab.name}
                            onClick={() => handleTabClick(tab.name)}
                            className={`py-2 px-4 text-sm font-medium ${
                                activeTab === tab.name
                                    ? 'bg-gray-100 text-gray-700'
                                    : 'bg-white text-gray-500 hover:bg-gray-100'
                            } ${
                                index === 0
                                    ? 'rounded-l-lg'
                                    : index === tabs.length - 1
                                    ? 'rounded-r-lg'
                                    : ''
                            } ${index < tabs.length - 1 ? '-ml-px' : ''} border border-gray-300`}
                        >
                            {tab.name}
                        </button>
                    ))}
                </nav>
                <div className="py-10 flex justify-center">
                    <div className="bg-white p-4 rounded-lg w-full max-w-8xl">
                        {tabs.find((tab) => tab.name === activeTab)?.component}
                    </div>
                </div>
            </div>
        </div>
    );
}
