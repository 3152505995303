import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthWrapper from "../../layouts/Auth/AuthWrapper";
import RegisterForm from "../../components/Auth/RegisterForm";

export default function Register() {
    const navigate = useNavigate();
    const { isAuthenticated, status } = useSelector((state) => state.auth);

    React.useEffect(() => {
        if (isAuthenticated) navigate('/dashboard');
    }, [isAuthenticated, navigate]);


    return (
        <AuthWrapper title="Sign up" description="Start your 30-day free trial.">
            <>
                <RegisterForm />
                <p className="mt-10 text-center text-sm text-gray-600">
                    {`Already have an account? `}
                    <NavLink
                        to="/login"
                        className="font-semibold text-brand-700 transition duration-500 hover:opacity-90"
                    >
                        Login
                    </NavLink>
                </p>
            </>
        </AuthWrapper>
    );
}
