import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import testimonialReducer from './testimonialSlice'
import authReducer from './authSlice';
import widgetSlice from './widgetSlice';

const persistConfig = {
    key: 'auth',
    storage,
}

const rootReducer = combineReducers({
    auth: authReducer,
    testimonials: testimonialReducer,
    widget: widgetSlice

});
const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    // reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production'
});

export default store;