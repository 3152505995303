import React, { useEffect, useState, createRef } from "react";
import { useFormContext } from "react-hook-form";
import UserTestimonial from "components/UserTestimonial";

import { useSelector, useDispatch } from "react-redux";
import { setTitle, setDescription, setBgColor } from "../../redux/widgetSlice"; // Adjust the import path
import { useScreenshot, createFileName } from "use-react-screenshot";

export default function DesignForm({ onShareClick, isShareActive, NewWidget, bgColor,
  setBgColor,
  title,
  setTitle,
  description,
  setDescription }) {
  console.log(NewWidget, 'widegtID');

  const { setValue, watch } = useFormContext();

  // const bgColor = useSelector((state) => state.widget.bgColor);
  // const title = useSelector((state) => state.widget.title);
  // const description = useSelector((state) => state.widget.description);

  const watchedBgColor = watch("bgColor", bgColor);
  const watchedTitle = watch("title", title);
  const watchedDescription = watch("description", description);


  const [activeTab, setActiveTab] = useState('javascript');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const handleBgColorChange = (e) => {
    const colorValue = e.target.value;
    setBgColor(colorValue);
    setValue("bgColor", colorValue);
  };

  const handleTitleChange = (e) => {
    const titleValue = e.target.value;
    setTitle(titleValue);
    setValue("title", titleValue);
  };

  const handleDescriptionChange = (e) => {
    const descriptionValue = e.target.value;
    setDescription(descriptionValue);
    setValue("description", descriptionValue);
  };

  const selectedTestimonials = useSelector((state) => state.testimonials.selectedTestimonials)
  // const testimonials = [
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Muhammad Anas",
  //     position: "Senior UX Designer at Bussfly | Ex-Sony",
  //     date: "Oct 6, 2023",
  //     testimonial: "Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.Asad knows how to provide value. I found him way back through a group recommendation and have been inspired by the way he teaches.",
  //     video: null,
  //     ratingName: "userRating1",
  //   },
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Sarah Khan",
  //     position: "Product Manager at TechCorp",
  //     date: "Oct 5, 2023",
  //     testimonial: "The experience was fantastic! I learned a lot from the sessions. The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.The experience was fantastic! I learned a lot from the sessions.",
  //     video: null,
  //     ratingName: "userRating2",
  //   },
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Ali Raza",
  //     position: "Software Engineer at WebSolutions",
  //     date: "Sep 30, 2023",
  //     testimonial: "Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.Highly recommended! The learning environment is great.",
  //     video: null,
  //     ratingName: "userRating3",
  //   },
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Fatima Zafar",
  //     position: "UX Researcher at DesignCo",
  //     date: "Sep 28, 2023",
  //     testimonial: "I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.I gained valuable insights that I can apply immediately.",
  //     video: null,
  //     ratingName: "userRating4",
  //   },
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Omer Ahmed",
  //     position: "Data Analyst at DataFirm",
  //     date: "Sep 25, 2023",
  //     testimonial: "This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.This was a transformative experience for my career.",
  //     video: null,
  //     ratingName: "userRating5",
  //   },
  //   {
  //     avatar: "https://placehold.co/40x40",
  //     name: "Hina Bashir",
  //     position: "Marketing Specialist at MarketPro",
  //     date: "Sep 22, 2023",
  //     testimonial: "I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.I loved the interactive sessions and the content covered.",
  //     video: null,
  //     ratingName: "userRating6",
  //   },
  // ];

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  return (
    <div className="flex flex-row flex-1 overflow-hidden h-full">
      <div className="flex-grow p-0 m-0 overflow-hidden" ref={ref}>
        <div
          className="text-white py-16"
          style={{
            background: `linear-gradient(to bottom, ${watchedBgColor} 30%, #EAE8F5 30%)`,
            height: '100%',
          }}
        >
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold text-center mb-2">{watchedTitle}</h1>
            <p className="text-center">{watchedDescription}</p>
          </div>
          <div className="flex-grow bg-transparent">
            <div className="grid grid-cols-3 gap-2 mt-4 justify-items-center">
              {selectedTestimonials.map((testimonial, index) => (
                <div key={index} className="border-b border-gray-200 p-2 bg-transparent">
                  <UserTestimonial
                    avatar={testimonial.image}
                    name={testimonial.name}
                    position={testimonial.position}
                    date={testimonial.date}
                    testimonial={testimonial.testimonial}
                    video={testimonial.type === 'video' && testimonial.video}
                    ratingName={testimonial._id}
                    rating={testimonial.stars}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      {/* Sidebars */}
      <div className="w- h-full border-l border-gray-200 bg-white px-4 py-8">
        {!isShareActive ? (
          <div className="">
            <div className="bg-white ">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                  Title
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  value={watchedTitle}
                  onChange={handleTitleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                  Description
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="description"
                  rows="3"
                  value={watchedDescription}
                  onChange={handleDescriptionChange}
                />
                <p className="text-gray-600 text-xs italic">
                  You can use markdown to format the text
                </p>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bgColor">
                  BG Color
                </label>
                <div className="relative">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="bgColor"
                    type="text"
                    value={bgColor}
                    onChange={handleBgColorChange}
                  />
                  <input
                    className="absolute left-2 top-2 w-6 h-6 rounded-full opacity-0"
                    type="color"
                    value={bgColor}
                    onChange={handleBgColorChange}
                  />
                  <div
                    className="absolute left-2 top-2 w-6 h-6 rounded-full cursor-pointer"
                    style={{ backgroundColor: bgColor }}
                    onClick={(e) => e.target.previousSibling.click()}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-64">
            <h2 className="text-lg font-semibold mb-2">Share Options</h2>
            <p className="text-sm text-gray-600 mb-4">Follow the instructions to share your widget.</p>

            <div className="mb-4">
              <p className="text-sm font-semibold mb-2">Embed Code</p>
              <div className="flex items-center mb-2">
                <button
                  onClick={() => handleTabClick('javascript')}
                  className={`text-sm font-semibold px-2 py-1 ${activeTab === 'javascript' ? 'border-b-2 border-blue-500' : 'text-gray-700'}`}
                >
                  Javascript
                </button>
                <button
                  onClick={() => handleTabClick('url')}
                  className={`text-sm font-semibold px-2 py-1 ${activeTab === 'url' ? 'border-b-2 border-blue-500' : 'text-gray-700'}`}
                >
                  URL
                </button>
                <button className="ml-auto text-gray-500">
                  <i className="fas fa-copy"></i>
                </button>
              </div>
              {activeTab === 'javascript' && (
                <textarea
                  className="w-full h-32 p-2 text-sm border rounded-md bg-gray-100"
                  readOnly
                >
                  {`${NewWidget ? `<script src="http://localhost:3000/widget/share/${NewWidget._id}"></script>` : `<script src="https://example.com/no-widget-created"></script>`}`}
                </textarea>
              )}
              {activeTab === 'url' && (
                <textarea
                  className="w-full h-32 p-2 text-sm border rounded-md bg-gray-100"
                  readOnly
                >
                  {`${NewWidget ? `http://localhost:3000/widget/share/${NewWidget._id}` : `https://example.com/no-widget-created`}`}

                </textarea>
              )}
            </div>
            <p className="text-sm text-gray-600 mb-4">Paste this code snippet where you want to display the embed on your site.</p>

            <p className="text-sm font-semibold mb-2">Supported Platforms</p>
            <div className="flex space-x-2 mb-4">
              <img alt="Platform 1" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/uNMmHzlWxNrhItGymtDKQiys0rC4wqBVW4Qw8VFOrspGPZ5E.jpg" width="24" />
              <img alt="Platform 2" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/G8tQEnhqEMaBF5E3ITcm4p2madxr5pQpwMZzeRjPWprKeklTA.jpg" width="24" />
              <img alt="Platform 3" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/g7ICZvLEqBo8OpxzlpjrYB1gEmz4n8M6x3lgAPUPDerLeklTA.jpg" width="24" />
              <img alt="Platform 4" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/C5isovtHlwobGtlJFpPNvYQmXDIB6fWjfUsWM2jOwvgT8klTA.jpg" width="24" />
              <img alt="Platform 5" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/jalzawAehjX9ViJzch1ojUOH9CktAlfLfmK75BrTkx0z4JLnA.jpg" width="24" />
              <img alt="Platform 6" className="w-6 h-6" height="24" src="https://storage.googleapis.com/a1aa/image/TuoSzrMr0tKGMdOQ5HuyT2AUQPUNrLUoyfERCrTftPSW8klTA.jpg" width="24" />
            </div>

            <div className="flex items-center mb-4">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="text-sm text-gray-500 mx-2">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <button onClick={downloadScreenshot} className="w-full py-2 mb-4 text-sm font-semibold text-gray-700 border border-gray-300 rounded-md">
              Export as PNG
            </button>
            <button className="w-full py-2 text-sm font-semibold text-white bg-purple-600 rounded-md">
              Done
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
