import React, { useEffect, useState } from "react";
import { EnvelopeIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import API from "API";
// import { updateUser } from '../../redux/authSlice';
import { updateUser } from "../../redux/authService";
import { toast } from "react-toastify";

export default function PersonalInfo() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        photo: null,
    });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const user = useSelector((state) => state.user);

    const fetchUserData = async (id) => {
        try {
            const res = await API.getUserbyId(id);

            setFormData({
                firstName: res.data.user.fullName.split(" ")[0],
                lastName: res.data.user.fullName.split(" ")[1],
                email: res.data.user.email,
                phoneNumber: res.data.user.phone,
                photo: res.data.user.profile,
            });
        } catch (error) {
            setError(error.message);
        }
    };
    useEffect(() => {
        fetchUserData(user._id);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const uploadImage = async (file) => {
        try {
            const res = await API.uploadImage(file);
            console.log(res, "RES HASSAN");
            setFormData((prevData) => ({
                ...prevData,
                photo: res.data.file,
            }));
        } catch (error) {
            setError(error.message);
        }
    };
    const handleFileChange = (e) => {
        uploadImage(e.target.files[0]);
    };

    const updateProfileSetting = async (formData, userId) => {
        try {
            const payload = {
                username: formData.username,
                fullName: formData.firstName + " " + formData.lastName,
                profile: formData.photo,
                phone: formData.phoneNumber,
                // "oldPassword": "12345678",
                // "newPassword": "12345678"
            };
            const res = await dispatch(updateUser({ userData: payload, id: userId }));
            if (res) {
                toast.success("Update Profile Successfully");
                setError("");
                setLoading(false);
            } else {
                toast.error("Update Profile Successfully");
                setError("");
                setLoading(false);
            }

            // fetchUserData(user._id)
        } catch (error) {
            setError(error.message);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        if (!formData.firstName || !formData.lastName || !formData.email) {
            setError("First name, last name, and email are required.");
            setLoading(false);
            return;
        }
        updateProfileSetting(formData, user._id);
    };

    return (
        <form onSubmit={handleSubmit} className="ml-2 mr-2 mt-2 mb-2">
            <h2 className="text-lg font-semibold mb-1">Personal info</h2>
            <p className="text-gray-500 mb-1 text-sm">
                Update your photo and personal details here.
            </p>
            <hr className="border-t border-gray-300 mb-6 mt-6" />

            {/* Name*/}
            <div className="flex items-center mb-4">
                <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">
                    Name
                </label>
                <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="mt-1 block w-1/4 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-indigo-500 sm:text-sm ml-4"
                    required
                />
                <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="mt-1 block w-1/4 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-indigo-500 sm:text-sm ml-4"
                    required
                />
            </div>
            <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

            {/* Email */}
            <div className="flex items-center mb-4">
                <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">
                    Email address
                </label>
                <div className="relative w-1/2 ml-4">
                    <EnvelopeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        // onChange={handleInputChange}
                        className="mt-1 block w-full pl-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-purple-500 sm:text-sm"
                        required
                        readOnly
                    />
                </div>
            </div>
            <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

            {/* Number */}
            <div className="flex items-center mb-4">
                <label className="w-1/3 block text-sm font-bold text-gray-700 mb-4">
                    Phone number
                </label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-1/2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-purple-500 sm:text-sm ml-4"
                    placeholder="+1 (555) 000-0000"
                />
            </div>
            <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

            {/* Photo */}
            <div className="flex items-center mb-4">
                <label className="w-1/3 block text-sm font-bold text-gray-700 mb-20">
                    Your photo
                </label>
                <div className="mt-2 flex items-center w-2/3 ml-4">
                    {formData.photo ? (
                        typeof formData.photo === "string" ? (
                            <img
                                src={`${process.env.REACT_APP_PROXY_URL}/${formData.photo}`}
                                alt="Profile"
                                className="h-16 w-16 rounded-full object-cover mb-8"
                            />
                        ) : (
                            <img
                                src={URL.createObjectURL(formData.photo)}
                                alt="Profile"
                                className="h-16 w-16 rounded-full object-cover mb-8"
                            />
                        )
                    ) : (
                        <div className="h-16 w-16 flex items-center justify-center bg-gray-100 rounded-full mb-14">
                            <span className="text-gray-500 text-xs">No Image</span>
                        </div>
                    )}

                    <div className="ml-4">
                        <div className="border-2 border-purple-500 rounded-md p-8 flex flex-col items-center">
                            <CloudArrowUpIcon className="h-6 w-6 text-purple-500 mb-1" />
                            <label className="text-xs font-medium text-indigo-600 cursor-pointer ">
                                Click to upload or drag and drop
                                <input
                                    type="file"
                                    name="photo"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="sr-only"
                                />
                            </label>
                            <p className="text-xs text-gray-500 mt-1">
                                SVG, PNG, JPG or GIF (max. 800x400px)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="border-t border-gray-300 w-full mb-6 mt-6" />

            {/* Submit Buttons */}
            <div className="mt-6 flex justify-end gap-2">
                <Button size="lg" variant="secondary-gray" disabled={loading}>
                    {loading ? "Deleting..." : "Delete"}
                </Button>
                <Button size="lg" disabled={loading}>
                    {loading ? "Saving..." : "Save"}
                </Button>
            </div>
        </form>
    );
}
