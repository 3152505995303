import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import classNames from "classnames";
import { StarIcon } from "@heroicons/react/24/solid";

const StarRating = ({
    totalStars = 5,
    starClass = "w-5 h-5 stars",
    label,
    starDiv,
    value = 0,
    onChange,
}) => {
    const [onHover, setOnHover] = useState(null);

    const Star = ({ index }) => {
        const filled = index < value;
        const combinedStarClass = classNames(
            starClass,
            "cursor-pointer",
            { "hover:text-[#FDB022]": !!onChange && !filled }, // Apply hover class only if onChange is provided and star is not filled
            { "text-[#FDB022]": filled || index < onHover, "text-gray-300": !filled && index >= onHover }
        );

        return (
            <div
                className={combinedStarClass}
            // onMouseEnter={() => setOnHover(index + 1)}
            // onMouseLeave={() => setOnHover(null)}
            // onClick={() => onChange && onChange(index + 1)}
            >
                <StarIcon />
            </div>
        );
    };

    return (
        <div>
            {label && (
                <label className="block text-xs lg:text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
            )}
            <div className={`${classNames(`flex mt-2 ${starDiv}`)}`}>
                {[...Array(totalStars)].map((_, i) => (
                    <Star key={i} index={i} />
                ))}
            </div>
        </div>
    );
};



const UserTestimonial = ({ avatar, name, position, testimonial, video, ratingName, date, onClick, rating }) => {
    const { watch } = useFormContext();
    const [videoUrl, setVideoUrl] = useState(null);
    console.log("Rendering UserTestimonial for:", name);
    useEffect(() => {
        if (video) {
            if (typeof video === 'string') {
                setVideoUrl(video);
            } else {
                setVideoUrl(URL.createObjectURL(video));
            }
        }
        return () => {
            if (videoUrl) URL.revokeObjectURL(videoUrl);
        };
    }, [video]);

    const ratingValue = ratingName ? watch(ratingName) : null;


    const isValidImageUrl = (url) => {
        // Check if the URL is a valid image URL
        const img = new Image();
        img.src = url;
        return img.complete && img.naturalWidth !== 0;
    };

    const imageUrl = avatar
        ? typeof avatar === "string"
            ? (isValidImageUrl(avatar) ? avatar : "/img/imagePlaceholder.svg")
            : (isValidImageUrl(URL.createObjectURL(avatar)) ? URL.createObjectURL(avatar) : "/img/imagePlaceholder.svg")
        : "/img/imagePlaceholder.svg";


    return (
        <div className="w-full max-w-xs lg:mt-2" onClick={onClick}>
            <div className="bg-white rounded-2xl py-6 px-6 flex flex-col gap-2 w-full mt-2 ">
                <div className="flex items-start mb-2">
                    <div className="flex items-center justify-center rounded-full bg-gray-100 border border-gray-200 w-10 h-10 mr-2">
                        <img
                            src={
                                imageUrl
                            }
                            alt={`Profile picture of ${name}`}
                            className="object-contain w-10 h-10 rounded-full"
                        />
                    </div>
                    <div className="flex flex-col text-left">
                        <h1 className="text-sm font-semibold">{name || "Anonymous User"}</h1>
                        <p className="text-gray-500 text-xs">{position || "Position not specified"}</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mb-1">
                    <div className="flex items-center">
                        {ratingName && (
                            <StarRating
                                name={ratingName}
                                label=""
                                starClass="w-4 h-4"
                                value={rating}
                            />
                        )}
                    </div>
                    <div className="text-gray-500 text-xs">{date}</div>
                </div>
                <p className="text-xs text-gray-700">{testimonial || "No testimonial available."}</p>
                {videoUrl && (
                    <video controls className="w-full mt-2">
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
        </div>
    );
};

export default UserTestimonial;
