// redux/testimonialSlice.js
import { createSlice } from '@reduxjs/toolkit';

const testimonialSlice = createSlice({
    name: 'testimonials',
    initialState: {
        selectedTestimonials: []
    },
    reducers: {
        toggleTestimonial: (state, action) => {
            const existingIndex = state.selectedTestimonials.findIndex(t => t._id === action.payload._id);
            if (existingIndex >= 0) {
                state.selectedTestimonials.splice(existingIndex, 1); // Remove if it exists
            } else {
                state.selectedTestimonials.push(action.payload); // Add if it does not exist
            }
        },
        removeTestimonial: (state, action) => {
            state.selectedTestimonials = state.selectedTestimonials.filter(t => t._id !== action.payload._id);
        },
    },
});

export const { toggleTestimonial, removeTestimonial } = testimonialSlice.actions;
export default testimonialSlice.reducer;
