// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { loginUser, logoutUser, registerUser, ensureLogin, updateUser } from './authService';

const initialState = {
    user: null, // Initially, no user is logged in
    isAuthenticated: false,
    status: "idle",
    error: ""
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.status = 'idle';
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.isAuthenticated = false;
                state.status = 'idle';
            })
            .addCase(ensureLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(ensureLogin.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.status = 'idle';
            })
            .addCase(ensureLogin.rejected, (state, action) => {
                state.error = action.payload;
                state.isAuthenticated = false;
                state.status = 'idle';
            })

            .addCase(updateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.status = 'idle';
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.error = action.payload;
                state.status = 'idle';
            })
            .addCase(registerUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                if (typeof action.payload !== "string") {
                    state.user = action.payload
                    state.isAuthenticated = true;
                }
                state.status = 'idle'
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.error = action.payload;
                state.isAuthenticated = false;
                state.status = 'idle';
            })

            .addCase(logoutUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(logoutUser.fulfilled, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                state.status = 'idle'
            })

    }
});



export default authSlice.reducer;
