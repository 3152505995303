import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "Wall of Love",
    description: "Here's what my clients say about me",
    bgColor: "#7F56D9",
};

const widgetSlice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setDescription: (state, action) => {
            console.log('action Called')
            state.description = action.payload;
        },
        setBgColor: (state, action) => {
            state.bgColor = action.payload;
        },
    },
});

export const { setTitle, setDescription, setBgColor } = widgetSlice.actions;
export default widgetSlice.reducer;
