import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../API";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData, thunkApi) => {
    const result = await API.login(userData);

    // thunkApi.dispatch(setUser(result.user));
    return result?.user || result;
  }
);

export const ensureLogin = createAsyncThunk(
  "auth/ensureLogin",
  async (userData, thunkApi) => {
    const result = await API.ensureLogin();

    return result.user;
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData, thunkApi) => {
    try {
      const result = await API.register(userData);

      // thunkApi.dispatch(setUser(result.user));
      return result.user;
    } catch (e) {
      return e.message;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, thunkApi) => {
    await API.logout();
  }
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ userData, id }, { rejectWithValue }) => {
    console.log(userData, id, "HASSAN CHECK")
    try {
      const response = await API.updateProfileSetting({ data: userData, id: id });
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
