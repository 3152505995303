import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Forms from "./pages/Forms";
import Widgets from "./pages/Widgets";
import ImportPage from "./pages/ImportPage";
import NewForm from "./pages/NewForm";
import WallOfLove from "./widget/WallOfLove.js";
// import { TestimonialList } from "./widget/TestimonialList.js";

import { useDispatch, useSelector } from "react-redux";
import { ensureLogin } from "./redux/authService";
import TestimonialModifier from "widget/TestimonialModifier";
import ViewTestimonal from "pages/ViewTestimonail";
import EmbedTestimonial from "pages/EmbedTestimonail";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DesignForm from "widget/components/DesignForm";
import WallOfLoveDesign from "widget/components/WallOfLoveDesign";

function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const prepWork = async () => {
      // await API.getCSRFToken();

      dispatch(ensureLogin());
    };

    prepWork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/testimonial/view/:id" element={<ViewTestimonal />} />
          <Route path="/testimonial/embed/:id" element={<EmbedTestimonial />} />

          <Route path="/logout" element={<Login logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />


          <Route path="/import-testimonials" element={<ImportPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/widgets" element={<Widgets />} />
          <Route path="/widgets/wall-of-love" element={<WallOfLove />} />
          {/* <Route path="/widgets/testimonial-list" element={<TestimonialList />} /> */}
          <Route path="/widget/share/:id" element={<WallOfLoveDesign />} />
          <Route path="/forms">
            <Route path="" element={<Forms />} />
            <Route path="create-new" element={<NewForm />} />
            <Route path="preview/:id" element={<NewForm editable={false} />} />
          </Route>
          <Route
            path="/testimonial/:id"
            element={<NewForm editable={false} testimonial={true} />}
          />
          <Route
            path="/testimonial/:id/:testimonialId"
            element={<NewForm editable={false} testimonial={true} />}
          />
          <Route path="/edit/testimonial/:id" element={<TestimonialModifier />} />
          {/* Other routes go here */}
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;